const klickartPerformantIframe = () => {
  const mediaSrcSelector = '[klickart-videoyoutube-link-src]'
  const mediaContainerElements = document.querySelectorAll(mediaSrcSelector);

  mediaContainerElements.forEach((mediaContainer) => {
    mediaContainer.addEventListener('click', function () {
      const src = this.getAttribute('klickart-videoyoutube-link-src');
      this.innerHTML = `<iframe width="600" height="332.5" src="${src}?autoplay=1" title="Klickart YouTube embed video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
    });
  });
};

export { klickartPerformantIframe }
